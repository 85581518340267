"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.api = void 0;
const react_1 = require("@reduxjs/toolkit/query/react");
const config_1 = require("config");
const utils_1 = require("utils");
const baseQuery = (0, react_1.fetchBaseQuery)({
    baseUrl: config_1.REACT_APP_API_URL,
    prepareHeaders: (headers) => {
        headers.set('Authorization', process.env.NODE_ENV === 'test'
            ? ''
            : `Bearer ${JSON.parse((0, utils_1.getCookie)('portalUser')).credential}`);
    },
});
const baseQueryWithCamelize = (0, utils_1.camelizeResponse)(baseQuery);
exports.api = (0, react_1.createApi)({
    reducerPath: 'internalPortalApi',
    baseQuery: baseQueryWithCamelize,
    endpoints: (build) => ({
        // Has exception in src/js/utils/camelizeResponse.ts
        manualPostFiles: build.mutation({
            query: (queryArg) => ({
                url: '/internal_portal/v1/files',
                method: 'POST',
                prepareHeaders: (headers) => {
                    headers.set('Content-Type', 'multipart/form-data');
                    return headers;
                },
                body: queryArg.body,
            }),
        }),
        postEnrollmentsMerchantApplicationIdFiles: build.mutation({
            query: (queryArg) => ({
                url: `/internal_portal/v1/locations/${queryArg.locationId}/enrollments/${queryArg.merchantApplicationId}/files`,
                method: 'POST',
                body: queryArg.body,
            }),
        }),
        // Fix for manager_email in PostInternalPortalV1LocationsLocationIdTurnOnAffApiArg
        postInternalPortalLocationsLocationIdTurnOnAff: build.mutation({
            query: (queryArg) => ({
                url: `/internal_portal/v1/locations/${queryArg.locationId}/turn_on_aff`,
                method: 'POST',
                body: queryArg.body,
            }),
        }),
    }),
});
