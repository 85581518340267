"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_cookie_1 = require("react-cookie");
const react_redux_1 = require("react-redux");
const react_router_dom_1 = require("react-router-dom");
const redux_1 = require("redux");
const redux_flash_1 = require("redux-flash");
const ab_tests_api_1 = require("ab-tests-api");
const components_1 = require("components");
const config_1 = require("config");
const context_1 = require("context");
const utils_1 = require("utils");
const actions = __importStar(require("../actions"));
const components_2 = require("../components");
const reducer_1 = require("../reducer");
const utils_2 = require("../utils");
function Home({ clearPractice, flashErrorMessage, practice, clearPersonalInfo, personalInfo, }) {
    // Hooks
    const { analytics } = (0, react_1.useContext)(context_1.AnalyticsContext);
    const history = (0, react_router_dom_1.useHistory)();
    const { search } = (0, react_router_dom_1.useLocation)();
    const query = (0, utils_1.parseQuery)(search);
    const [cookies, setCookie] = (0, react_cookie_1.useCookies)(['proveAb', 'proveStoreAb']);
    const [assignProveVariant, { isLoading }] = (0, ab_tests_api_1.usePostAbTestsV1ParticipantsAssignVariantTestSlugMutation)();
    const handleClick = async (url) => {
        analytics?.track('ClickPrequalifyButton', {});
        if (config_1.PATH.PROVE?.BASE) {
            if (config_1.PROVE_CHANNEL_PREQUALIFY) {
                const testGroup = await (0, utils_2.assignProveAbVariant)(cookies, assignProveVariant, setCookie, 'consumer_prove_no_store_test');
                if (testGroup === 'treatment') {
                    const provePath = `${config_1.PATH.CREDIT_PORTAL}${config_1.PATH.PROVE?.BASE}`;
                    history.push(provePath);
                }
                else if (testGroup === 'control') {
                    history.push(url);
                }
            }
            else {
                history.push(url); // Old Path
            }
        }
        else {
            history.push(url);
        }
    };
    (0, react_1.useEffect)(() => {
        const initialError = query[config_1.QUERY_KEY.ERROR];
        if (initialError) {
            const errMsg = (0, utils_1.apiErrorToErrorMessage)(initialError);
            (0, utils_1.reportError)('flashError', {
                componentName: 'Home',
                funcName: 'useEffect',
                errType: 'query',
                errURL: window.location.pathname,
                errMsg: errMsg,
            });
            flashErrorMessage(errMsg);
        }
        if ((0, utils_1.isPresent)(practice))
            clearPractice();
        if ((0, utils_1.isPresent)(personalInfo))
            clearPersonalInfo();
    }, []);
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)("div", { className: "home-content", children: (0, jsx_runtime_1.jsxs)("div", { className: "home-container", children: [(0, jsx_runtime_1.jsxs)("div", { className: "home-left", children: [(0, jsx_runtime_1.jsx)("p", { className: "practice", children: "Select Your Practice" }), (0, jsx_runtime_1.jsx)("div", { className: "simple-form", children: (0, jsx_runtime_1.jsx)(components_2.PracticeSearchInput, { searchPromptText: "CONTINUE WITH PRACTICE", className: "w-70" }) }), (0, jsx_runtime_1.jsx)(react_router_dom_1.Link, { to: config_1.APPLICATION_FINDER_URL, children: "Application already started?" })] }), (0, jsx_runtime_1.jsx)("div", { className: "separator", children: (0, jsx_runtime_1.jsx)("div", { className: "or", children: "OR" }) }), (0, jsx_runtime_1.jsxs)("div", { className: "home-right", children: [(0, jsx_runtime_1.jsx)("p", { className: "practice", children: "Haven't chosen a doctor yet?" }), (0, jsx_runtime_1.jsx)(components_1.Button, { type: "button", onClick: () => handleClick(config_1.WIZARD_URL), className: "button-warn-outline qualify", submitting: isLoading, children: "PRE-QUALIFY" })] })] }) }) }));
}
function mapStateToProps(state) {
    return {
        practice: reducer_1.selectors.practice(state),
        personalInfo: reducer_1.selectors.personalInfo(state),
    };
}
const mapDispatchToProps = {
    clearPractice: actions.clearPractice,
    clearPersonalInfo: actions.clearPersonalInfo,
    flashErrorMessage: redux_flash_1.flashErrorMessage,
};
exports.default = (0, redux_1.compose)((0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps))(Home);
