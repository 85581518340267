"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DebouncedInput = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("@coreui/react");
const react_2 = require("react");
// A debounced input react component
function DebouncedInput({ value: initialValue, onChange, debounce = 500, }) {
    const [value, setValue] = (0, react_2.useState)(initialValue);
    (0, react_2.useEffect)(() => {
        setValue(initialValue);
    }, [initialValue]);
    (0, react_2.useEffect)(() => {
        const timeout = setTimeout(() => {
            onChange(value);
        }, debounce);
        return () => clearTimeout(timeout);
    }, [value]);
    return ((0, jsx_runtime_1.jsx)(react_1.CFormInput, { placeholder: "Type to filter...", value: value, onChange: (e) => setValue(e.target.value), type: "text", "data-testid": "filter-input" }));
}
exports.DebouncedInput = DebouncedInput;
