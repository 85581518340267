"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("@coreui/react");
const react_final_form_1 = require("react-final-form");
const components_1 = require("components");
const FieldsBankingDetails = () => {
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)("div", { className: "", children: (0, jsx_runtime_1.jsxs)(react_1.CRow, { xs: { gutterX: 5 }, children: [(0, jsx_runtime_1.jsx)(react_1.CCol, { lg: 4, md: 12, children: (0, jsx_runtime_1.jsx)(react_final_form_1.Field, { disabled: true, altclass: "coreUI", name: "bankName", label: 'Bank Name', component: components_1.InputText, placeholder: "", type: "text" }) }), (0, jsx_runtime_1.jsx)(react_1.CCol, { lg: 4, md: 12, children: (0, jsx_runtime_1.jsx)(react_final_form_1.Field, { disabled: true, altclass: "coreUI", name: "bankAcctNumber", label: 'Bank Account Number', component: components_1.InputNumber, required: true, placeholder: "Enter account number", type: "text", isAllowed: (values) => {
                                const { floatValue, value, formattedValue } = values;
                                return (value.length < 18 ||
                                    (value === '' &&
                                        formattedValue === '' &&
                                        floatValue === undefined));
                            } }) }), (0, jsx_runtime_1.jsx)(react_1.CCol, { lg: 4, md: 12, children: (0, jsx_runtime_1.jsx)(react_final_form_1.Field, { disabled: true, altclass: "coreUI", name: "bankRoutingNumber", label: 'Routing Number', component: components_1.InputNumber, required: true, placeholder: "Enter routing number", type: "text", isAllowed: (values) => {
                                const { floatValue, value, formattedValue } = values;
                                return (value.length < 10 ||
                                    (value === '' &&
                                        formattedValue === '' &&
                                        floatValue === undefined));
                            } }) })] }) }) }));
};
exports.default = FieldsBankingDetails;
