"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParseCell = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
function ParseCell({ cell }) {
    const [showTooltip, setShowTooltip] = (0, react_1.useState)(false);
    const cellValue = String(cell.getValue());
    const [tooltipPosition, setTooltipPosition] = (0, react_1.useState)({ x: 0, y: 0 });
    const isUrl = cellValue.startsWith('http://') || cellValue.startsWith('https://');
    let renderValue = cellValue;
    if (renderValue.length >= 50 || isUrl) {
        renderValue = isUrl
            ? cellValue.slice(0, 50) + '...'
            : renderValue.slice(0, 50) + '...';
        return ((0, jsx_runtime_1.jsxs)("div", { className: `clickable-el rm-fixed ${isUrl ? 'tooltip-wrapper' : ''}`, onMouseEnter: (e) => {
                setShowTooltip(true);
                setTooltipPosition({ x: e.clientX, y: e.clientY });
            }, onMouseLeave: () => setShowTooltip(false), "data-testid": "data-cell", children: [renderValue, showTooltip && ((0, jsx_runtime_1.jsx)("div", { className: `tooltip-container visible ${isUrl ? 'url-tooltip' : ''}`, "data-testid": "data-tooltip", style: {
                        top: tooltipPosition.y,
                        left: tooltipPosition.x - 110,
                        maxWidth: '550px',
                    }, children: isUrl ? ((0, jsx_runtime_1.jsx)("a", { href: cellValue, target: "_blank", rel: "noopener noreferrer", children: cellValue })) : (cellValue) }))] }));
    }
    return (0, jsx_runtime_1.jsx)("div", { "data-testid": "data-cell", children: renderValue });
}
exports.ParseCell = ParseCell;
