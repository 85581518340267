"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const components_1 = require("components");
const merchant_management_v1_api_1 = require("merchant-management-v1-api");
function ProgramManagementHeader({ setCurrentProgramShown, currentLocation, }) {
    // Query - Get
    const { data: globalConfig } = (0, merchant_management_v1_api_1.useGetMerchantManagementV1FeatureFlagsGlobalQuery)();
    const globalConfigFix = globalConfig;
    // Query - Get
    const { data: lenderEligibility, isFetching: isFetchingLenderEligibility } = (0, merchant_management_v1_api_1.useGetMerchantManagementV1LocationsIdEnrollmentLendersQuery)({
        id: currentLocation.locationId,
    });
    const lenderEligibilityFix = lenderEligibility;
    // Memo
    const subprimeEnrollmentState = (0, react_1.useMemo)(() => {
        const result = {
            isInProgressOrComplete: false,
            enrollmentOptions: [],
        };
        lenderEligibilityFix?.forEach((lender) => {
            const locationState = currentLocation.address.state;
            if (lender.enrollmentApplicationId) {
                result.isInProgressOrComplete = true;
            }
            if (lender.type === 'subprime' &&
                lender.status === 'Not Enrolled' &&
                !lender.enrollmentApplicationId &&
                lender.statesAvailable.includes(locationState)) {
                result.enrollmentOptions.push(lender);
            }
        });
        return result;
    }, [lenderEligibilityFix, currentLocation]);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [isFetchingLenderEligibility && (0, jsx_runtime_1.jsx)(components_1.Spinner, {}), globalConfigFix?.featureFlags.mfeEnrollments &&
                subprimeEnrollmentState.enrollmentOptions &&
                subprimeEnrollmentState.enrollmentOptions.length > 0 ? ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: !subprimeEnrollmentState.isInProgressOrComplete ? ((0, jsx_runtime_1.jsxs)("div", { className: "view-programs w100", children: [(0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)(components_1.H2, { variant: "headline-medium", children: "Alphaeon Access" }), (0, jsx_runtime_1.jsx)(components_1.P, { variant: "body-medium", children: "Looking to approve more patients? The Alphaeon Access program, our subprime solution, helps with approving up to 85% of previously declined patients. Enroll today to get started." })] }), (0, jsx_runtime_1.jsx)(components_1.Button, { type: "button", className: "button-warn", onClick: () => setCurrentProgramShown('access'), children: (0, jsx_runtime_1.jsx)("span", { style: { textWrap: 'nowrap' }, children: "VIEW PROGRAMS" }) })] })) : null })) : null] }));
}
exports.default = ProgramManagementHeader;
