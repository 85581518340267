"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const framer_motion_1 = require("framer-motion");
const react_router_dom_1 = require("react-router-dom");
const config_1 = require("config");
const merchant_portal_components_1 = require("merchant-portal-components");
const DashboardActions = ({ currentLocation, }) => {
    const ANIMX_SPEED_BOXES = 0.15;
    const pushApplicationEnabled = currentLocation?.pushApplicationEnabled;
    const dashboardAppEnabled = currentLocation?.dashboardApplicationEnabled;
    // Hooks
    const history = (0, react_router_dom_1.useHistory)();
    const handleClickActionBox = (action) => {
        history.push({
            pathname: action.link,
            state: { isTransact: action.title === 'Transact' ? true : false },
        });
    };
    return ((0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)("div", { className: "dashboard__actions", children: config_1.DASHBOARD_ACTIONS.map((action, idx) => {
                if (action.id === 'apply' &&
                    !dashboardAppEnabled &&
                    pushApplicationEnabled !== true) {
                    return false;
                }
                return ((0, jsx_runtime_1.jsx)(framer_motion_1.motion.div, { className: "dashboard-action-box", initial: { opacity: 0, scale: 1, y: 100 }, animate: {
                        opacity: 1,
                        scale: 1,
                        y: 0,
                    }, transition: {
                        delay: Number((ANIMX_SPEED_BOXES * idx) / 2),
                        duration: 0.5,
                    }, children: (0, jsx_runtime_1.jsx)("div", { className: "dashboard__actions--item", onKeyPress: () => handleClickActionBox(action), role: "button", tabIndex: 0, onClick: () => handleClickActionBox(action), children: (0, jsx_runtime_1.jsxs)("div", { className: "action-content", children: [(0, jsx_runtime_1.jsx)(action.icon, {}), (0, jsx_runtime_1.jsx)("div", { className: "action-content--title", children: action.title }), (0, jsx_runtime_1.jsx)("div", { className: "action-content--description", children: action.description }), (0, jsx_runtime_1.jsx)(merchant_portal_components_1.AuthorizedLinkButton, { to: action.link, className: "button-primary button-warn-outline", requiredPermission: action.requiredPermission, permissionSelectionCriteria: action.id === 'apply' ? 'OR' : 'ALL', children: action.title })] }) }) }, action.id));
            }) }) }));
};
exports.default = DashboardActions;
